
body::-webkit-scrollbar {
	display: none;
  
  }
  .active {
	color: red;
  }
  
  .container { position:relative; }
  .container video {
	  position:relative;
	  z-index:0;
  }
  .overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
  }
   
  .each-slide-effect > div {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	height: 550px;
  }
  
  .each-slide-effect span {
	padding: 20px;
	font-size: 20px;
	background: #efefef;
	text-align: center;
  }



  *, *:before, *:after {
	box-sizing: inherit;
  }
  .cd__main{
	 background: linear-gradient(to right, #3f2b96, #a8c0ff) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  .text-center {
	text-align: center;
  }
  
  .color-white {
	color: #fff;
  }
  
  .box-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 35px 15px;
	width: 100%;
  }
  
  @media screen and (min-width:1380px) {
	.box-container {
	  flex-direction: row
	}
  }
  
  .box-item {
	position: relative;
	-webkit-backface-visibility: hidden;
	width: 415px;
	margin-bottom: 35px;
	max-width: 100%;
  }
  
  .flip-box {
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
	perspective: 1000px;
	-webkit-perspective: 1000px;
  }
  
  .flip-box-front,
  .flip-box-back {
	background-size: cover;
	background-position: center;
	border-radius: 8px;
	min-height: 475px;
	-ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
	transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
	-webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
  }
  
  .flip-box-front {
	-ms-transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
	background-color: rgba(0, 0, 0, 0.6);

  }
  
  .flip-box:hover .flip-box-front {
	-ms-transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
  }
  
  .flip-box-back {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	
	background-color: rgba(0, 0, 0, 0.6);

	  
	-ms-transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	transform: rotateY(180deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-back {
	-ms-transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
	-webkit-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;
	transform-style: preserve-3d;
  }
  
  .flip-box .inner {
	position: absolute;
	left: 0;
	width: 100%;
	padding: 60px;
	outline: 1px solid transparent;
	-webkit-perspective: inherit;
	perspective: inherit;
	z-index: 2;
	
	transform: translateY(-50%) translateZ(60px) scale(.94);
	-webkit-transform: translateY(-50%) translateZ(60px) scale(.94);
	-ms-transform: translateY(-50%) translateZ(60px) scale(.94);
	top: 50%;
  }
  
  .flip-box-header {
	font-size: 34px;
  }
  
  .flip-box p {
	font-size: 20px;
	line-height: 1.5em;
  }
  
  .flip-box-img {
	margin-top: 25px;
  }
  
  .flip-box-button {
	background-color: transparent;
	border: 2px solid #2D9596;
	border-radius: 2px;
	color: #2D9596;
	cursor: pointer;
	font-size: 20px;
	font-weight: bold;
	margin-top: 25px;
	padding: 15px 20px;
	text-transform: uppercase;
  }
   @tailwind base;
  @tailwind components;
  @tailwind utilities;
  